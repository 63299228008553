import {CognitoAuthProvider} from "ra-auth-cognito";
import appConfig from "../config";
import dataProvider from "./dataProvider";

const clientId = appConfig.COGNITO_APP_CLIENT_ID;
const userPoolId = appConfig.COGNITO_USERPOOL_ID;
const hostedUIUrl = appConfig.COGNITO_APP_HOSTED_UI_URL;

const cognitoAuthProvider = CognitoAuthProvider({
    mode: 'oauth',
    clientId: clientId,
    userPoolId: userPoolId,
    hostedUIUrl:  hostedUIUrl,
});

const authProvider = {
    ...cognitoAuthProvider,
    handleCallback: async () => {
        const user = await cognitoAuthProvider.handleCallback!();
        const session = user.getSignInUserSession();

        localStorage.setItem('token', session.getAccessToken().getJwtToken());

        return Promise.resolve(user);
    },
    checkError: async ({ status } : { status: number }) => {
        if (status === 401) {
            throw new Error('Unauthorized');
        }
    },
    getPermissions: async () => {
        return await dataProvider.getPermissions();
    }
}

export default authProvider;
