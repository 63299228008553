import React from "react";
import Box from "@mui/material/Box";
import {ArrayField, Datagrid, SimpleShowLayout, TextField, useRecordContext} from "react-admin";
import {TariffElement, TariffGroup} from "../../../../providers/dto/tariffGroup";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Typography} from "@mui/material";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TariffTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TariffElements({elements}: {elements: TariffElement[]}) {
    return <Box>{elements.map((element, index) => (
        <Box key={index}>
            <Typography variant="h6">Price components</Typography>
            <ArrayField record={element} source="price_components">
                <Datagrid bulkActionButtons={false} rowClick={false}>
                    <TextField source="type" />
                    <TextField source="price" />
                    <TextField source="step_size" />
                </Datagrid>
            </ArrayField>
        </Box>))}
    </Box>;
}

export default function TariffsTabs() {
    const record = useRecordContext<TariffGroup>();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {record?.subtariffs?.map((subtariff, index) => (
                        <Tab key={subtariff.tariffkey} label={subtariff.tariff.id} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </Box>
            {record?.subtariffs?.map((subtariff, index) => (
                <TariffTabPanel key={subtariff.tariffkey} value={value} index={index}>
                    <SimpleShowLayout>
                        <TextField record={subtariff} source="tariffkey" />
                        <TextField record={subtariff} source="tariff.id" />
                        <TextField record={subtariff} source="tariff.currency" />
                        <Typography variant="h5">Elements</Typography>
                        <TariffElements elements={subtariff.tariff.elements} />
                    </SimpleShowLayout>
                </TariffTabPanel>
            ))}
        </Box>
    );
}
