import {
    Show, SimpleShowLayout, TextField,
} from 'react-admin';

import React from "react";
import TariffsTabs from "./components/TariffsTabs";

export const TariffgroupShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="tariffgroup_id" />
            <TariffsTabs/>
        </SimpleShowLayout>
    </Show>
);
