import {Admin, CustomRoutes, Resource} from "react-admin";

import { BrowserRouter, Route } from 'react-router-dom';

import apiDataProvider from "../providers/dataProvider";

import Dashboard from "./features/Dashboard";
import {TariffgroupCreate} from "./features/tariffgroups/TariffgroupCreate";
import {TariffgroupEdit} from "./features/tariffgroups/TariffgroupEdit";
import {TariffgroupList} from "./features/tariffgroups/TariffgroupList";
import {TarifflinkList} from "./features/tarifflink/TarifflinkList";
import {TarifflinkCreate} from "./features/tarifflink/TarifflinkCreate";
import {TarifflinkShow} from "./features/tarifflink/TarifflinkShow";
import {CdrList} from "./features/cdrs/CdrList";
import {CdrEdit} from "./features/cdrs/CdrEdit";
import {LocationList as ExploitationLocationList} from "./features/exploitation/locations/LocationList";

import authProvider from "../providers/authProvider";
import AppLayout from "./layout/Layout";
import appTheme from "./themes/appTheme";
import {CdrImportJobList} from "./features/cdr-import-jobs/CdrImportJobList";
import {i18nProvider} from "../i18nProvider";
import {LocationEdit as ExploitationLocationEdit} from "./features/exploitation/locations/LocationEdit";
import {CdrIcon, CdrsImportIcon, LocationIcon, TariffgroupIcon, TarifflinkIcon, IssuerIcon, PlaceIcon, DeployIcon} from "./constants";
import {IssuerList} from "./features/facturation/issuers/IssuerList";
import {IssuerShow} from "./features/facturation/issuers/IssuerShow";
import LocationsSection from "./features/dashboard/LocationsSection";
import CdrSection from "./features/dashboard/session/CdrSection";
import DeploymentSection from "./features/dashboard/DeploymentSection";
import StatsInvoicing from "./features/dashboard/StatsInvoicing";
import PageStatsLocations from "./pages/statistics/PageStatsLocations";
import PageStatsCdrs from "./pages/statistics/PageStatsCdrs";
import PageStatsInvoicing from "./pages/statistics/PageStatsInvoicing";
import PageStatsDeployment from "./pages/statistics/PageStatsDeployment";
import ExploitationAdminPage from "./pages/exploitation/ExploitationAdminPage";
import ExploitationDailyStatsMailingConfigPage from "./pages/exploitation/ExploitationDailyStatsMailingConfigPage";
import SettingsPage from "./pages/settings/SettingsPage";
import {TariffgroupShow} from "./features/tariffgroups/TariffgroupShow";

const App = () => {
    return (
        <BrowserRouter>
            <Admin layout={AppLayout}
                   lightTheme={appTheme.light}
                   darkTheme={appTheme.dark}
                   dataProvider={apiDataProvider}
                   authProvider={authProvider}
                   i18nProvider={i18nProvider}
                   loginPage={false}
                   dashboard={Dashboard} requireAuth disableTelemetry={true}>
                <Resource name="tariffgroups" list={TariffgroupList} create={TariffgroupCreate}
                                             edit={TariffgroupEdit} show={TariffgroupShow} icon={TariffgroupIcon}/>
                <Resource name="tarifflinks" list={TarifflinkList} create={TarifflinkCreate} show={TarifflinkShow} icon={TarifflinkIcon}/>
                <Resource name="cdrs" icon={CdrIcon} list={CdrList} edit={CdrEdit}/>
                <Resource name="cdr-import-jobs" icon={CdrsImportIcon} list={CdrImportJobList} />
                <Resource name="exploitation-locations" icon={LocationIcon} list={ExploitationLocationList}
                          edit={ExploitationLocationEdit} options={{ label: 'Locations' }}/>
                <Resource name="invoicingIssuers" icon={IssuerIcon} list={IssuerList}
                          show={IssuerShow} options={{ label: 'Issuers' }}/>
                <Resource name="statistics/location" icon={PlaceIcon} options={{label : 'Location'}} list={LocationsSection}/>
                <Resource name="statistics/cdrs" icon={CdrIcon} options={{label : 'CDRs'}} list={CdrSection}/>
                <Resource name="statistics/deployment" icon={DeployIcon} list={DeploymentSection} options={{label: 'Deploiement'}}/>
                <Resource name="statistics/invoicing" icon={LocationIcon} list={StatsInvoicing} options={{label: 'Pre-facturation'}}/>

                <CustomRoutes>
                    <Route path="/statistics/locations" element={<PageStatsLocations />} />
                    <Route path="/statistics/cdrs" element={<PageStatsCdrs />} />
                    <Route path="/statistics/invoicing" element={<PageStatsInvoicing />} />
                    <Route path="/statistics/deployment" element={<PageStatsDeployment />} />
                    <Route path={ExploitationAdminPage.route} element={<ExploitationAdminPage />} />
                    <Route path={ExploitationDailyStatsMailingConfigPage.route} element={<ExploitationDailyStatsMailingConfigPage />} />
                    <Route path={SettingsPage.route} element={<SettingsPage />} />
                </CustomRoutes>
            </Admin>
        </BrowserRouter>
    );
};

export default App;
