import {
    Create,
    CheckboxGroupInput,
    useNotify, useDataProvider, Loading, useTranslate, TabbedForm, TextInput, ArrayInput, SimpleFormIterator, required
} from 'react-admin';
import { useQuery } from '@tanstack/react-query';
import {Alert} from "@mui/material";
import {AppPermissions} from "../../../helpers/permissions";

const SettingsPage = () => {
    const notify = useNotify();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const { data, isPending, error } = useQuery({
        queryKey: ['config', 'get'],
        queryFn: () => dataProvider.getConfig()
    });

    const onSuccess = () => {
        notify(`resources.configuration.messages.saved`, {type: 'info'});
    };

    if (isPending) return <Loading />;
    if (error) return <Alert variant={'outlined'} title={error.message} severity={'error'}/>;

    return (
        <>
            <h1>{translate('resources.configuration.pageTitle')}</h1>
            <Create resource="configuration" mutationOptions={{ onSuccess }} record={data}>
                <TabbedForm  syncWithLocation={false}>
                    <TabbedForm.Tab label="resources.configuration.tabs.permissions" path="permissions">
                        <ArrayInput source={'permissions'}>
                            <SimpleFormIterator>
                                <TextInput source="group" validate={required()}/>
                                <CheckboxGroupInput choices={Object.keys(AppPermissions).map(key => {
                                    // @ts-ignore
                                    return {id: AppPermissions[key], name: "resources.configuration.permissions.permissions." + key}
                                })} source="permissions" row={false} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </TabbedForm.Tab>
                </TabbedForm>
            </Create>
        </>
    );
};

SettingsPage.route = '/configuration';

export default SettingsPage;
